import { ProjectEntity, ProjectStatusEnum } from "../../api/task/TaskApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark, faFaceSmileWink, faFilm, faMagnifyingGlassChart,
  faSpinner,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useFaceEditStores } from "../../stores/FaceEditStores";

interface IProps {
  projectItem: ProjectEntity;
}

export function FaceEditProjectItem(props: IProps) {
  const { projectItem } = props;
  const status = projectItem.project_status[1];
  let img_url = "";
  img_url = projectItem.project_segments[0].image_url;
  const video_duration = projectItem.project_segments[0].video_duration;
  const estimated_time = projectItem.project_segments[0].estimated_time;
  const status_code = projectItem.project_status[0];
  debugger
  const tag = projectItem.project_segments[0].tag;
  const { fetchTasks, fetchTaskFromCache, projects, selectProject, project } =
    useFaceEditStores((state) => ({
      fetchTasks: state.fetchTasks,
      fetchTaskFromCache: state.fetchTaskFromCache,
      projects: state.projects,
      selectProject: state.selectProject,
      project: state.project,
    }));

  const selected = projectItem.id == project?.id;

  const secondsToTimeFormat = () => {
    if (video_duration) {
      const minutes = Math.floor(video_duration / 60);
      const secondParts = video_duration % 60;
      const minute = minutes % 60;

      return (
        minute.toString().padStart(2, "0") +
        ":" +
        secondParts.toString().padStart(2, "0")
      );
    } else return undefined;
  };

  return (
    <div
      onClick={() => {
        selectProject(projectItem);
      }}
      className={`border-${
        selected ? "primary-color" : "gray"
      } border-[2px] mb-[14px] mr-[2px] h-[104px] rounded-[10px] z-10`}
    >
      <div className="relative w-full h-[100px] ">
        <img
          src={img_url}
          className={`w-full h-full rounded-[9px] object-cover z-0`}
        ></img>

        {status_code == ProjectStatusEnum.Generated && (
          <div className="absolute flex flex-row justify-center items-end top-0 left-0 w-full h-full ">
            <div className="absolute flex flex-row justify-center items-center top-0 left-0 w-full h-full ">
              <div className="flex flex-col items-center  bg-gray-a rounded p-[2px]  pl-[6px] pr-[6px] ">
                <FontAwesomeIcon
                  icon={faFaceSmileWink}
                  size={"lg"}
                  color="green"
                  className=" pt-[4px] "
                ></FontAwesomeIcon>
                <div className=" text-[14px] font-medium p-[1px] text-green-600 ">
                  Done
                </div>
              </div>
            </div>
            <div className="flex bg-gray-a p-[2px] pl-[4px] pr-[4px] text-gray-d text-[12px] font-medium ml-[2px] ml-[2px] mr-[5px]  rounded-[10px] ">
              {secondsToTimeFormat()}
            </div>
          </div>
        )}
        {tag && (
          <div className="absolute right-0 top-0 bg-gray-a text-white text-[11px] pl-[4px] rounded-[4px]">
            <div>Demo</div>
          </div>
        )}
        {(status_code == ProjectStatusEnum.PromptGenerating  || status_code == ProjectStatusEnum.VideoAnalysed)  && (
          <div className="absolute flex flex-row justify-center items-center top-0 left-0 w-full h-full ">
            <div className="flex flex-col items-center bg-gray-a rounded p-[2px] ">
              <FontAwesomeIcon
                icon={faFilm}
                size={"lg"}
                color={'white'}
                className="pt-[2px]"
              ></FontAwesomeIcon>
              <div className=" text-[14px] font-medium p-[3px] text-white">
                Analyzed
              </div>
            </div>
          </div>
        )}
        {(status_code == ProjectStatusEnum.SwapGenerateFailed ||
          status_code == ProjectStatusEnum.FileNotSupport ||
          status_code == ProjectStatusEnum.FileOverSize ||
          status_code == ProjectStatusEnum.VideoNotContainFaces) && (
          <div className="absolute flex flex-row justify-center items-start top-0 left-0 w-full h-full ">
            <div className="flex flex-col items-center p-[2px]">
              <FontAwesomeIcon
                icon={faCircleXmark}
                size={"sm"}
                color="red    "
                className="ml-[3px]"
              ></FontAwesomeIcon>
              <div className=" text-[14px] p-[3px] text-red-500 ">Failed</div>
            </div>
          </div>
        )}
        {(status_code == ProjectStatusEnum.SwapGenerating || status_code == ProjectStatusEnum.VideoAnalysing) && (
          <div className="absolute flex flex-row justify-center items-center top-0 left-0 w-full h-full ">
            <div className=" bg-gray-a rounded-[10px] pt-[4px] flex flex-col items-center pt-[8px]">
              <FontAwesomeIcon
                icon={faSpinner}
                size={"lg"}
                color="white"
                className="ml-[3px]"
              ></FontAwesomeIcon>
              <div className=" text-[12px] font-medium p-[3px] text-white mt-[4px]">
                {status_code == ProjectStatusEnum.SwapGenerating?"Generating":"Analysing"}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
