import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Spinner,
} from "@material-tailwind/react";
import {ImgComparisonSlider} from "@img-comparison-slider/react";
import {useState} from "react";

interface IProps {
    show: boolean;
    msg: string;
    title: string;
    onCancel: () => void;
}

export function FaceEditDemoShowDialog(
    props: IProps = {
        title: "Demo Show",
        msg: "",
        show: false,
        onCancel: () => {
        },
    }
) {
    const {show, msg, onCancel, title} = props;
    return (
        <Dialog
            placeholder={""}
            open={show}

            handler={(e) => {
                onCancel()
            }}
            className="bg-dark-bg"
        >
            <DialogHeader placeholder={""} className="text-white">
                Face swap comparison
            </DialogHeader>
            <DialogBody placeholder={""} className="text-white">
                <div className="relative">
                    <ImgComparisonSlider
                        value={41}
                    >
                        <figure slot="first">
                            <img
                                width="100%"
                                src="https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/2721718179821_.pic.jpg"
                            />
                            <figcaption
                                className="left-4 bg-white border border-slate-200 rounded-1xl text-black opacity-80 p-2
              absolute top-1/4 -translate-y-1/2 leading-100 text-center text-base"
                            >
                                Origin Video
                            </figcaption>
                        </figure>
                        <figure slot="second">
                            <img
                                width="100%"
                                src="https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/2731718179847_.pic.jpg"
                            />
                            <figcaption
                                className="right-4 bg-white border border-slate-200 rounded-1xl text-black opacity-80 p-2
              absolute top-1/4 -translate-y-1/2 leading-100 text-center text-base"
                            >
                                Swapped Video
                            </figcaption>
                        </figure>
                    </ImgComparisonSlider>
                </div>
            </DialogBody>
            <DialogFooter placeholder={""}>
                <Button
                    placeholder={""}
                    variant="text"
                    color="red"
                    onClick={(e) => {
                        e.stopPropagation();
                        onCancel();
                    }}
                    className="mr-1 text-white"
                >
                    <span className="text-lg">OK</span>
                </Button>
            </DialogFooter>
        </Dialog>
    );
}
