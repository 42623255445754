import React, {useEffect, useRef} from "react";
import {StyleSelectorBox} from "../../components/create/StyleSelecorBox";
import {ModelSelector} from "../../components/create/ModelSelector";
import {TagSelector} from "../../components/create/TagSelector";
import {SizeSelector} from "../../components/create/SizeSelector";
import {NumberSelector} from "../../components/create/NumberSelector";
import {CreateButton} from "../../components/create/CreateButton";
import {useCreateStores} from "../../stores/CreateStores";
import {PromptInput} from "../../components/create/PromptInput";
import {useLocation, useNavigate} from "react-router-dom";
import {ErrorDialog} from "../../components/create/ErrorDoialog";
import {PromptHistory} from "../../components/create/PromptHistory";
import {LoadingDialog} from "../../components/create/LoadingDoialog";
import {useUserStores} from "../../stores/UserStores";
import {logEvent} from "firebase/analytics";
import {analytics, SOURCE_ID_KEY} from "../../App";
import {EVENT_PAGE_CREATE} from "../../util/Event";
import {Footer} from "../../components/common/Footer";

export function Create() {
    const {
        resetStatus,
        showErrDialog,
        errorMsg,
        dismiss,
        queryMaterial,
        tabs,
        showUploadingDialog,
        fetchFaces,
        fetchFacesFromLocal,
        uploadingFaceModel,
        cancelFaceModel,
        uploadingDialogTitle,
        recoverCreateEntity,
        initOnboarding,
        showFaceMode
    } = useCreateStores((state) => ({
        resetStatus: state.resetStatus,
        showErrDialog: state.showErrDialog,
        errorMsg: state.errMessage,
        dismiss: state.dismissErrDialog,
        queryMaterial: state.queryMaterial,
        tabs: state.tabs,
        showUploadingDialog: state.showUploadingDialog,
        fetchFaces: state.fetchFaces,
        fetchFacesFromLocal: state.fetchFacesFromLocal,
        uploadingFaceModel: state.uploadingFaceModel,
        cancelFaceModel: state.cancelFaceModel,
        uploadingDialogTitle: state.uploadingDialogTitle,
        recoverCreateEntity: state.recoverCreateEntity,
        initOnboarding : state.initOnboarding,
        showFaceMode:state.showFaceMode
    }))

    const {fetchUserInfo} = useUserStores((state) => ({
        fetchUserInfo: state.fetchUserInfo
    }))

    const navigate = useNavigate();

    const handleUnmount = () => {
        resetStatus()
        console.log('handleUnmount Create page')
    }

    useEffect(() => {
        console.log('init Create page')
        initOnboarding()
        queryMaterial()
        fetchFaces()
        fetchFacesFromLocal()
        fetchUserInfo()
        const sourceId = localStorage.getItem(SOURCE_ID_KEY)
        logEvent(analytics, EVENT_PAGE_CREATE, {
            page_path: EVENT_PAGE_CREATE,
            source_id:sourceId
        });
        return handleUnmount
    }, []);

    const showTabs =()=>{
        if(tabs && tabs.length>0){
            return true
        }
        return false
    }
    const cancelQuery = () => {
        cancelFaceModel()
    }
    return (
        <div className='h-screen overflow-y-auto bg-dark-bg no-scrollbar'>
            <div className=" pl-4 pr-4 lg:pl-[20%] lg:pr-[20%] w-screen h-screen bg-dark-bg pt-[20px] ">
                <ErrorDialog msg={errorMsg} show={showErrDialog}
                             onConfirm={() => {
                                 dismiss()
                                 navigate('/subscribe')
                             }} onCancel={() => {
                    dismiss()
                }}></ErrorDialog>
                <LoadingDialog  show={showUploadingDialog} title={uploadingDialogTitle} msg={''} onCancel={()=>{cancelQuery()}}></LoadingDialog>
                <PromptInput></PromptInput>
                <PromptHistory></PromptHistory>
                <StyleSelectorBox></StyleSelectorBox>
                {/* 上传图片作为模型*/}
                {showFaceMode && <ModelSelector></ModelSelector>}
                {showTabs() && <TagSelector></TagSelector>}x
                <SizeSelector></SizeSelector>
                <NumberSelector></NumberSelector>
                <CreateButton></CreateButton>
            </div>
                <Footer></Footer>
        </div>
    )
}
