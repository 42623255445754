import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from "@material-tailwind/react";
import React from "react";

interface IProps {
  show: boolean;
  msg: string;
  title: string;
  onCancel: () => void;
}

export function HomeHintDialog(props: IProps) {
  const { show, msg, onCancel, title } = props;

  return (
    <Dialog
      placeholder={""}
      open={show}
      handler={(e) => {}}
      className="bg-dark-bg"
    >
      <DialogHeader placeholder={""} className="text-white">
        warning
      </DialogHeader>
      <DialogBody
        placeholder={""}
        className="text-white flex flex-col place-items-center"
      >
        <div className="text-neutral-400  text-center">
          This website is intended for mature audiences as it contains
          Al-generated images with adult content.
        </div>

        <div className="text-red-600 text-lg text-center">
          YOU MUST BE OVER 18 YEARS OLD TO ENTER THIS WEBSITE
        </div>
        <div className="text-neutral-400  text-center">
          By entering this site, you are certifying that you are of legal adult
          age. By using the site, you agree to our Terms of Service.
        </div>
        <div className="m-4">
          <Button
            className="mr-4"
            size="md"
            color="purple"
            placeholder={undefined}
            onClick={() => {
              onCancel();
            }}
          >
            Yes
          </Button>
          <Button
            size="md"
            color="purple"
            placeholder={undefined}
            onClick={() => {
              window.close();
            }}
          >
            No
          </Button>
        </div>
        <div className="text-neutral-400  text-center">
          Any resemblance of the generated content on this website to real
          individuals is purely coincidental. We use cookies for basic analytics
          and spam detection
        </div>
      </DialogBody>
      <DialogFooter placeholder={""}>
        <div></div>
      </DialogFooter>
    </Dialog>
  );
}
