import React from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Link, useSearchParams} from 'react-router-dom'
import Navbar from "./components/navbar"
import {Create} from "./pages/create/Create";
import {Login} from "./pages/users/Login";
import {
    BrowserRouter,
    Routes,
} from "react-router-dom";
import {TaskDetail} from "./pages/TaskDetail";
import {Subscribe} from "./pages/subscribe/Subscribe";
import {Toaster} from "react-hot-toast";
import {MyCreation} from "./pages/my-creation/MyCreation";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {AccountInfo} from "./pages/accout-info/Account-Info";
import {PaymentResult} from "./pages/payment/PaymentResult";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {Home} from "./pages/home/Home";
import {PrivacyScreen} from "./pages/privacy";
import {TeamsService} from "./pages/teams-service";
import VConsole from 'vconsole';
import {FaceEdit} from "./pages/face-edit/Face-Edit";
const firebaseConfig = {
    apiKey: "AIzaSyAQDEfyXn0SXoHL2HyzyJ4amfxgqLk7Mg8",
    authDomain: "deloris-408816.firebaseapp.com",
    projectId: "deloris-408816",
    storageBucket: "deloris-408816.appspot.com",
    messagingSenderId: "183052959449",
    appId: "1:183052959449:web:10a1653d5c0a7725a648c8",
    measurementId: "G-CZJEWLVY9S"
};
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export  const INVITE_CODE_KEY = 'code'
export  const SOURCE_ID_KEY = 'sourceId'
export  const MODE_KEY = 'mode'

// or init with options
function App() {
    let [searchParams] = useSearchParams();
    let inviteCode = searchParams.get(INVITE_CODE_KEY);
    let sourceId = searchParams.get(SOURCE_ID_KEY);
    inviteCode = 'newbee'
    if(inviteCode) {
        console.log('inviteCode', inviteCode)
        localStorage.setItem(INVITE_CODE_KEY,inviteCode)
    }
    if(sourceId){
        localStorage.setItem(SOURCE_ID_KEY,sourceId)
    }
    const mode = searchParams.get(MODE_KEY);
    if (mode == 'debug') {
        const vConsole = new VConsole({theme: 'dark'});
    }

    return (
        <GoogleOAuthProvider clientId="183052959449-ibbp02i1eq1d61snk2242ltcq2uhk0vk.apps.googleusercontent.com">
            <div className="App h-screen w-screen bg-red-500 flex flex-col">

                <Navbar></Navbar>
                <Routes>
                    <Route path="/home" element={<Home />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/create" element={<Create />} />
                    <Route path="login/*" element={<Login />} />
                    <Route path="task/*" element={<TaskDetail />} />
                    <Route path="subscribe/*" element={<Subscribe />} />
                    <Route path="account-info/*" element={<AccountInfo />} />
                    <Route path="my-creations/*" element={<MyCreation />} />
                    <Route path="payment-result/*" element={<PaymentResult />} />
                    <Route path="pp/*" element={<PrivacyScreen />} />
                    <Route path="pp/*" element={<PrivacyScreen />} />
                    <Route path="tos/*" element={<TeamsService />} />
                    <Route path="face-edit/*" element={<FaceEdit />} />
                    <Route path="face-swap/*" element={<FaceEdit />} />
                </Routes>
                <Toaster />
            </div>
        </GoogleOAuthProvider>
    );
}

export default App;
