import { Button } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCopy,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { userTaskStores } from "../../stores/TaskStores";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useCreateStores } from "../../stores/CreateStores";
import { Segment } from "../../api/task/TaskApi";
import { useFaceEditStores } from "../../stores/FaceEditStores";

interface IProps {
  onDismiss?: () => void;
}

let index = 0;

export function setCarouselIndex(newIndex: number) {
  index = newIndex;
}

export function FaceEditButtonActionPanel(props: IProps) {
  const { onDismiss } = props;
  const navigate = useNavigate();
  const {
    showPreview,
    previewOriginVideo,
    project,
    setShowDeleteDialog,
    newProject,
  } = useFaceEditStores((state) => ({
    showPreview: state.showOriginPreview,
    previewOriginVideo: state.previewOriginVideo,
    project: state.project,
    setShowDeleteDialog: state.setShowDeleteDialog,
    newProject: state.newProject,
  }));

  const download = () => {
    let downloadUrl = null;
    if (previewOriginVideo) {
      if (
        project &&
        project.project_segments &&
        project.project_segments[0].origin_video_url
      ) {
        downloadUrl = project.project_segments[0].origin_video_url;
      }
    } else {
      if (
        project &&
        project.project_segments &&
        project.project_segments[0].gen_video_url
      ) {
        downloadUrl = project.project_segments[0].gen_video_url;
      }
    }
    if (downloadUrl) {
      let filename = "video.mp4";
      const link = document.createElement("a");
      const urlPars = downloadUrl.split("/");
      filename = urlPars[urlPars.length - 1];
      link.href = downloadUrl;
      link.download = filename;
      link.click();
    }
  };

  const showDialog = () => {
    setShowDeleteDialog(true);
  };

  const toggleOrigin = () => {
    showPreview(!previewOriginVideo);
  };

  const showToggle = () => {
    if (
      project &&
      project.project_segments &&
      project.project_segments.length > 0 &&
      project.project_segments[0].gen_video_url
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="flex bottom-[0px] w-full  flex-col justify-start items-center h-[65px] p-0 lg:p-0">
      <div className="  flex flex-row justify-between w-full pt-[15px]  h-[70px]">
        <div className="text-white flex flex-row ">
          <Button
            placeholder={""}
            variant={"outlined"}
            onClick={(e) => {
              e.stopPropagation();
              download();
            }}
            className="bg-dark-bg flex-col flex w-[40px]  h-[40px] lg:h-[45px] justify-center items-center"
          >
            <FontAwesomeIcon size={"lg"} icon={faArrowDown} />
          </Button>
          <Button
            placeholder={""}
            variant={"outlined"}
            onClick={(e) => {
              e.stopPropagation();
              showDialog();
            }}
            className="bg-dark-bg ml-[10px] flex-col flex w-[40px]  h-[40px] lg:h-[45px] justify-center items-center"
          >
            <FontAwesomeIcon size={"lg"} icon={faTrashCan} />
          </Button>
        </div>
        <div className="text-white flex flex-row ml-[10px]">
          {showToggle() && (
            <Button
              placeholder={""}
              variant={"outlined"}
              onClick={() => {
                toggleOrigin();
              }}
              className="bg-dark-bg flex-col flex h-[40px] lg:h-[45px] justify-center  text-[14px]"
            >
              {previewOriginVideo ? "Swap Video" : "Origin Video"}
            </Button>
          )}

          {/*{!showToggle() && <Button*/}
          {/*    placeholder={''}*/}
          {/*    variant={"outlined"}*/}
          {/*    className="bg-dark-bg flex-col flex h-[40px] lg:h-[45px] justify-center  text-[14px]">*/}
          {/*    {'Video Generating'}*/}
          {/*</Button>*/}
          {/*}*/}

          <Button
            placeholder={""}
            variant={"outlined"}
            onClick={() => {
              newProject();
            }}
            className="bg-dark-bg ml-[10px] flex-col flex  h-[40px] lg:h-[45px] justify-center items-center text-[14px]"
          >
            NEW
          </Button>
        </div>
      </div>
    </div>
  );
}
