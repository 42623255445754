import {useGoogleOneTapLogin} from "@react-oauth/google";
import React from "react";

export interface IProps{
    onClick:()=>void
}
export  function AppleLoginBtn(iProps:IProps) {
    const {onClick} = iProps

    return ( <div
        className='font-medium cursor-pointer w-[100%] bg-gray h-[60px] text-black rounded-[30px] text-[22px] flex flex-rows items-center justify-center'
        onClick={() => {
            // login()
        }}>
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.39 12.9a5.33 5.33 0 0 1 2.587-4.512 5.596 5.596 0 0 0-4.383-2.369c-1.868-.19-3.592 1.078-4.586 1.078-.994 0-2.395-1.054-3.952-1.03a5.9 5.9 0 0 0-4.958 2.996c-2.107 3.624-.538 9.013 1.57 11.939.957 1.433 2.155 3.056 3.735 2.996 1.581-.059 2.096-.97 3.928-.97 1.832 0 2.395.97 3.952.935 1.557-.036 2.659-1.469 3.664-2.902a12.967 12.967 0 0 0 1.653-3.376 5.222 5.222 0 0 1-3.21-4.784m-3.987-8.75c.853-1.145 1.279-2.639 1.184-4.151-1.297.122-2.5.832-3.352 1.978-.824 1.11-1.227 2.56-1.117 4.022 1.282.012 2.496-.67 3.285-1.848"
                fill="#15162E" fill-rule="evenodd">
                <path
                    d="M18.39 12.9a5.33 5.33 0 0 1 2.587-4.512 5.596 5.596 0 0 0-4.383-2.369c-1.868-.19-3.592 1.078-4.586 1.078-.994 0-2.395-1.054-3.952-1.03a5.9 5.9 0 0 0-4.958 2.996c-2.107 3.624-.538 9.013 1.57 11.939.957 1.433 2.155 3.056 3.735 2.996 1.581-.059 2.096-.97 3.928-.97 1.832 0 2.395.97 3.952.935 1.557-.036 2.659-1.469 3.664-2.902a12.967 12.967 0 0 0 1.653-3.376 5.222 5.222 0 0 1-3.21-4.784m-3.987-8.75c.853-1.145 1.279-2.639 1.184-4.151-1.297.122-2.5.832-3.352 1.978-.824 1.11-1.227 2.56-1.117 4.022 1.282.012 2.496-.67 3.285-1.848"
                    fill="#15162E" fill-rule="evenodd"></path>
            </path>
        </svg>
        <div className='ml-[10%] flex flex-col'>
            <div className=' w-full'>
                Continue with Apple
            </div>
            <div className='text-[12px] w-full'>
                (Coming online soon)
            </div>
        </div>
    </div>);

}