import {useNavigate, useSearchParams} from "react-router-dom";
import {Button, Spinner} from "@material-tailwind/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faCircleExclamation, faExclamation} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect} from "react";
import {logEvent} from "firebase/analytics";
import {analytics, SOURCE_ID_KEY} from "../../App";
import {EVENT_ACTION_PAYMENT_FAILED, EVENT_ACTION_PAYMENT_SUCCESS, EVENT_PAGE_SUBSCRIBE} from "../../util/Event";

export function PaymentResult() {

    const [result, setResult] = useSearchParams();

    const navigate = useNavigate()

    const type = result.get("result");

    useEffect(()=>{
        if (type == 'success') {
            const sourceId = localStorage.getItem(SOURCE_ID_KEY)
            logEvent(analytics, EVENT_ACTION_PAYMENT_SUCCESS, {
                page_path: EVENT_ACTION_PAYMENT_SUCCESS,
                source_id:sourceId
            });
        } else {
            const sourceId = localStorage.getItem(SOURCE_ID_KEY)
            logEvent(analytics, EVENT_ACTION_PAYMENT_FAILED, {
                page_path: EVENT_ACTION_PAYMENT_FAILED,
                source_id:sourceId
            });
        }
    },[type])

    const gotoCreate = ()=>{
        navigate('/create')
    }

    const gotoPayment = ()=>{
        navigate('/subscribe')
    }

    return (<div className='flex flex-col justify-center items-center  w-full h-screen  bg-dark-bg'>
        {(type === 'success') ?
            <div className='text-[24px] font-bold text-white flex flex-row justify-center items-center flex-col'>
                <FontAwesomeIcon color={'green'} size={"4x"} icon={faCircleCheck}/>
                <div className='mt-[2rem]'>Congratulations!  Your Payment is Successful</div>
                <Button
                    placeholder={''}
                    onClick={gotoCreate}
                    className="normal-case bg-gradient-to-r from-blue-500 to-pink-500 flex flex-row justify-center items-center text-[22px] bg-gray w-full  h-500 border-gray border-2 rounded-[10px] h-[60px] mt-[4rem]">
                    Goto Create
                </Button>
            </div>
            : <div className='text-[24px] font-bold text-white flex flex-row justify-center items-center flex-col'>
                <FontAwesomeIcon color={'red'} size={"4x"} icon={faCircleExclamation}/>
                <div className='mt-[2rem]'>  Sorry, Your Payment is Failed</div>
                <Button
                    placeholder={''}
                    onClick={gotoPayment}
                    className="normal-case bg-gradient-to-r from-blue-500 to-pink-500 flex flex-row justify-center items-center text-[22px] bg-gray w-full  h-500 border-gray border-2 rounded-[10px] h-[60px] mt-[4rem]">
                    Try Again
                </Button>
            </div>
        }
    </div>)
}