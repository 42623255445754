import React from "react";

export function ProBenefitInfo(){
    return(<div className='flex flex-col justify-center items-center lg:w-[50%]'>
        <div className='flex flex-row justify-center h-[50px]'>
            <div className='text-[24px] font-bold flex flex-row justify-center items-center text-primary-light-color'>
               Exclusive Discount Only Today
            </div>
        </div>
        <div>
            <div className='flex flex-row text-white text-[16px] '>
                <div>up to </div>
                <div className='text-primary-light-color  text-[17px]'> 80% </div>
                <div>off for first subscription</div>
            </div>
        </div>
        <div className='flex flex-row justify-center h-[50px]'>
            <div className='text-[24px] font-bold text-white flex flex-row justify-center items-center'>
              <div className='font-bold'>Pro Benefit</div>
            </div>
        </div>
        <div className='flex flex-col items-start items-center text-[16px]  w-full '>
            <div className='flex flex-col whitespace-nowrap lg:flex-row justify-between w-full '>
                <div className='text-white flex-1 flex justify-start mt-[5px]'>+100 credits per month</div>
                <div className='text-white flex-1 flex justify-start mt-[5px]'>+Unlock multitask.</div>
            </div>
            <div className='flex flex-col whitespace-nowrap lg:flex-row justify-between w-full'>
                <div className='text-white flex-1 flex justify-start mt-[5px]'>+Priority queue</div>
                <div className='text-white flex-1 flex justify-start mt-[5px]'>+Face swap image generation.</div>
            </div>
        </div>
    </div>)
}