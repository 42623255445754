import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {
    faInstagram,
    faSquareInstagram,
    faTiktok,
    faFacebookF,
    faTwitter,
    faYoutube,
    faDiscord
} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import {useNavigate} from "react-router-dom";


export function Footer() {

    const navigator = useNavigate()
    const gotoYoutube = () => {
        window.open('https://youtube.com/')
    }

    const gotoIns = () => {
        window.open('https://youtube.com/')
    }
    const gotoTT = () => {
        window.open('https://www.tiktok.com/')
    }
    const gotoFacebook = () => {
        window.open('https://www.facebook.com/')
    }
    const gotoTwitter = () => {
        window.open('https://twitter.com/deloris_ai')
    }
    const gotoMail = () => {
        window.open('mailto:deloris@deloris-ai.com?subject=Feedback&body=Body%20Content')
    }

    const gotoDiscord = () => {
        window.open('https://discord.com')
    }

    const gotoAffiliate = () => {
        window.open('https://docs.google.com/forms/d/1PIL8dfPHkKX0FV0PzRttcpgIWUt0PYB0pvscTM9t9iE/edit')
    }
    const gotoTeamsOfService = () => {
        navigator('/tos')
    }

    const gotoPrivacy = () => {
        navigator('/pp')
    }
    return (
        <div className=' hidden lg:inline-block w-full justify-end'>
        <div
            className='flex  text-gray-d flex-row justify-between items-center bg-dark-bg w-full h-[60px] pl-[5%] pr-[5%]'>
            <div className=' text-[14px]  flex flex-row  items-center'>
                <div className=' hover:text-white '>Deloris Ai-0.0.9</div>
                <div className='ml-10  hover:text-white '>community</div>
                <div className='flex flex-row'>
                    {/*<div*/}
                    {/*    onClick={gotoIns}*/}
                    {/*    className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>*/}
                    {/*    <FontAwesomeIcon icon={faInstagram} size={"lg"} color={'white'}/>*/}
                    {/*</div>*/}

                    {/*<div*/}
                    {/*    onClick={gotoTT}*/}
                    {/*    className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>*/}
                    {/*    <FontAwesomeIcon icon={faTiktok} size={"lg"} color={'white'}/>*/}
                    {/*</div>*/}

                    {/*<div*/}
                    {/*    onClick={gotoFacebook}*/}
                    {/*    className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>*/}
                    {/*    <FontAwesomeIcon icon={faFacebookF} size={"lg"} color={'white'}/>*/}
                    {/*</div>*/}
                    {/*<div*/}
                    {/*    onClick={gotoYoutube}*/}
                    {/*    className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>*/}
                    {/*    <FontAwesomeIcon icon={faYoutube} size={"lg"} color={'white'}/>*/}
                    {/*</div>*/}
                    <div
                        onClick={gotoTwitter}
                        className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>
                        <FontAwesomeIcon icon={faTwitter} size={"lg"} color={'white'}/>
                    </div>
                </div>
            </div>
            <div className='flex text-gray-d  text-[14px]  flex-row  justify-center items-center'>
                <div className='flex flex-row'>Contact Us</div>
                <div
                    onClick={gotoMail}
                    className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>
                    <FontAwesomeIcon icon={faEnvelope} size={"lg"} color={'white'}/>
                </div>

                {/*<div*/}
                {/*    onClick={gotoDiscord}*/}
                {/*    className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>*/}
                {/*    <FontAwesomeIcon icon={faDiscord} size={"lg"} color={'white'}/>*/}
                {/*</div>*/}

                <div
                    onClick={gotoAffiliate}
                    className='flex hover:cursor-pointer hover:text-white flex-row ml-2'>Affiliate
                </div>

                <div
                    onClick={gotoTeamsOfService}
                    className='flex hover:cursor-pointer hover:text-white flex-row ml-2 '>Teams of service
                </div>
                <div
                    onClick={gotoPrivacy}
                    className='flex hover:cursor-pointer hover:text-white flex-row ml-2 '>Privacy policy
                </div>
                <a className='hover:cursor-pointer hover:text-white flex-row ml-2' href="https://dokeyai.com/" title="Dokey AI Tools Directory">DokeyAI</a>
            </div>
        </div>
        </div>
            )
}