import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {userTaskStores} from "../../stores/TaskStores";

export function NSFW() {
    const {displayContent} = userTaskStores((state)=>({
        displayContent:state.displayOriginContent
    }))
    return (<div
        onClick={displayContent}
        className="absolute  bg-gradient-to-r from-red-950 to-pink-800 w-full h-full flex flex-col justify-center items-center">
        <div className="flex flex-col ">
            <FontAwesomeIcon className="ml-[10px]" size={'3x'} color='white' icon={faCircleExclamation}/>
            <div className="text-white  text-[18px] lg:text-[22px] mt-[20px] pl-[50px] pr-[50px] ">creation may contain NSFW content</div>
            <div className="text-white  text-[16px] lg:text-[20px] mt-[20px] pl-[50px] pr-[50px]">This will not consume your credits，Please use positive prompts.</div>
        </div>
        {/*<div*/}
        {/*    className="cursor-pointer select-none pl-[10px]  pr-[10px] bg-gradient-to-r from-blue-500 to-pink-500 flex flex-row justify-center items-center text-[22px] bg-gray   h-500 border-gray rounded-[10px] h-[50px] mt-[230px]">*/}
        {/*    <div className="text-white text-[18px]">I understand and wish to proceed</div>*/}
        {/*</div>*/}
    </div>)
}