import {BraintreePayPalButtons, PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import {CreationItem} from "../../components/my-creation/CreationItem";
import {Grid} from "@mui/material";
import {debounce} from "lodash"
import {Action, State, userCreationStores} from "../../stores/CreationsStores";
import {useEffect, useRef} from "react";
import {Segment} from "../../api/task/TaskApi";
import {PageLoading} from "../../components/common/PageLoading";
import {PageEmpty} from "../../components/common/PageEmpty";
import {PageError} from "../../components/common/PageError";
import {DeleteDialog} from "../../components/task/DeleteDoialog";
import {TaskDetail} from "../TaskDetail";
import {TaskEntity} from "../../model/task/TaskEntity";
import {useUserStores} from "../../stores/UserStores";
import {useNavigate} from "react-router-dom";
import {PageFootLoading} from "../../components/common/PageFootLoading";
import {logEvent} from "firebase/analytics";
import {analytics, SOURCE_ID_KEY} from "../../App";
import {EVENT_PAGE_LOGIN, EVENT_PAGE_MY_CREATIONS} from "../../util/Event";


export function MyCreation() {
    const style = {"label": "paypal", "layout": "vertical"};
    const {userEntity} = useUserStores((state) => ({
        userEntity: state.userEntity
    }))
    const {
        fetchTasks,
        projects,
        showQuerying,
        querying,
        showEmpty,
        showError,
        errorMsg,
        deleteTask,
        deleteSuccess,
        resetDeleteResult,
        showDeleteDialog,
        deleting,
        showDelete,
        hideDelete,
        showDetail,
        setShowDetail,
        detailTask,
        refreshTasks,
        page,
        lastPage,
        loadMore,
        resetStatus,
        has_next,
        fetchTaskFromCache,
        cacheProjects
    } = userCreationStores((state: State & Action) => ({
        fetchTasks: state.fetchTasks,
        projects: state.projects,
        showQuerying: state.showQuerying,
        showEmpty: state.showEmpty,
        showError: state.showError,
        errorMsg: state.errorMsg,
        showDeleteDialog: state.showDeleteDialog,
        deleting: state.deleting,
        deleteTask: state.deleteTask,
        deleteSuccess: state.deleteSuccess,
        resetDeleteResult: state.resetDeleteResult,
        showDelete: state.showDelete,
        hideDelete: state.hideDelete,
        setShowDetail: state.setShowDetail,
        detailTask: state.detailTask,
        showDetail: state.showDetail,
        refreshTasks: state.refreshTasks,
        page: state.page,
        lastPage: state.lastPage,
        loadMore: state.loadMore,
        querying: state.querying,
        resetStatus: state.resetStatus,
        has_next: state.has_next_page,
        fetchTaskFromCache:state.fetchTaskFromCache,
        cacheProjects:state.cacheProjects
    }))
    const navigate = useNavigate()

    const handleUnmount = () => {
        resetStatus()
    }
    useEffect(() => {
        console.log('init fetchTasks')
        fetchTasks()
        fetchTaskFromCache()
        const sourceId = localStorage.getItem(SOURCE_ID_KEY)
        logEvent(analytics, EVENT_PAGE_MY_CREATIONS, {
            page_path: EVENT_PAGE_MY_CREATIONS,
            source_id:sourceId
        });
        return handleUnmount
    }, [])

    useEffect(() => {
        if (!userEntity) {
            navigate(`/login`)
        }
    }, [userEntity])

    useEffect(() => {
        if (deleteSuccess) {
            resetDeleteResult()
            refreshTasks()
        }
    }, [deleteSuccess])

    const onRetry = () => {
        fetchTasks()
    }
    const handleConfirm = () => {
        deleteTask()
    }

    const hideDialog = () => {
        hideDelete()
    }

    const onDetail = (task: TaskEntity) => {
        setShowDetail(true, task)
    }

    const ref = useRef(document.createElement('div'));

    useEffect(() => {
        const handleScroll = () => {
            console.log(`handleScroll  querying:${querying} time:${new Date().getTime()}`)
            if (ref.current) {
                const {scrollTop, clientHeight, scrollHeight} = ref.current;
                //初始内容高度小于容器高度
                // const fillData = (scrollTop + clientHeight) < scrollHeight
                // console.log(`fillData:${fillData}`)
                if (scrollHeight - scrollTop - clientHeight < 1) {
                    // 已滚动到底部
                    console.log('invoke debounce')
                    const debounceLoadMore = debounce(() => {
                        console.log(`load more ${querying}`)
                        //非请求中且还有下一页
                        if (!querying && has_next) {
                            loadMore()
                        } else {
                            console.log('load more but is querying')
                        }
                    }, 800)
                    debounceLoadMore()
                }
            }
        };

        if (ref && ref.current) {
            ref.current.addEventListener("scroll", handleScroll);
            // return () => ref.current.removeEventListener("scroll", handleScroll);
        }
    }, []);

    const showPagingFooter = () => {
        if (!showQuerying && !showEmpty && projects && has_next) {
            return (<PageFootLoading></PageFootLoading>)
        }
        return (<div className='text-white'></div>)
    }

    const getProjects = ()=>{
        if(projects && projects.length>0){
            return projects
        } else if(cacheProjects && cacheProjects.length>0){
            return cacheProjects
        }
        return []
    }

  return (()=>{
        if(showDetail){
            return(<TaskDetail task={detailTask}
                               onDeleted={() => {
                                   setShowDetail(false, undefined)
                                   refreshTasks()
                               }}
                               onDismiss={() => {
                                   setShowDetail(false, undefined)
                               }}></TaskDetail>)
        } else {
            return (<div
                className="flex relative flex-column justify-center items-start h-screen w-screen bg-dark-bg ">
                <DeleteDialog open={showDeleteDialog} onConfirm={() => {
                    handleConfirm()
                }} onCancel={() => {
                    hideDialog()
                }} deleting={deleting}></DeleteDialog>
                {showQuerying && <PageLoading></PageLoading>}
                {!showQuerying && showError && <PageError onRetry={onRetry} errorMsg={errorMsg}></PageError>}
                {!showQuerying && showEmpty && <PageEmpty errorMsg={errorMsg}></PageEmpty>}

                <div
                    className="flex flex-col w-[90%] h-screen overflow-y-auto  no-scrollbar  pt-[20px] pl-[20px] h-[50%]"
                    ref={ref}>

                    {!showQuerying && !showEmpty &&
                        <Grid container spacing={{xs: 2, md: 3}} columns={20}>

                            {getProjects().map((item, index) => (
                                <Grid xs={10} sm={5} md={4} key={index}>
                                    <div className="p-[10px] rotate-[10px] f-full h-full">
                                        <CreationItem onDelete={showDelete} onDetail={(task) => {
                                            if (task) {
                                                onDetail(task)
                                            }
                                        }
                                        } project={item}></CreationItem>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>


                    }
                    {showPagingFooter()}
                </div>
            </div>)
        }
    })()
}