import React, { useEffect, useState } from "react";
import "./FaceEdit.css";
import { FaceEditCommitAction } from "./Face-Edit-Commit-Action";
import { useCreateStores } from "../../stores/CreateStores";
import { useFaceEditStores } from "../../stores/FaceEditStores";
import { useNavigate } from "react-router-dom";
import { FaceModelInfoDialog } from "../../components/create/FaceModelInfoDialog";
import { LoadingDialog } from "../../components/create/LoadingDoialog";
import { FaceModelItem } from "../../components/face-edit/FaceModelItem";
import { FaceModelUploaderButton } from "../../components/face-edit/FaceModelUploaderButton";
import { Grid } from "@mui/material";

export function FaceEditRight() {
  const {
    faces,
    fetchFaces,
    fetchFacesFromLocal,
    showHint,
    uploadFace,
    showFaceModelInfoDialog,
    hideFaceModelInfo,
    uploadingDialogTitle,
    showUploadingDialog,
    cancelFaceModel,
    queryAnalysisResult,
    uploadingFaceModel,
  } = useCreateStores((state) => ({
    createEntity: state.createEntity,
    faces: state.faces,
    fetchFaces: state.fetchFaces,
    fetchFacesFromLocal: state.fetchFacesFromLocal,
    showHint: state.showHint,
    uploadFace: state.uploadFace,
    showFaceModelInfoDialog: state.showFaceModelInfoDialog,
    hideFaceModelInfo: state.hideFaceModelInfo,
    uploadingDialogTitle: state.uploadingDialogTitle,
    showUploadingDialog: state.showUploadingDialog,
    uploadingFaceModel: state.uploadingFaceModel,
    cancelFaceModel: state.cancelFaceModel,
    queryAnalysisResult: state.queryAnalysisResult,
  }));

  const { createEntity, setModel, resetStatus } = useFaceEditStores(
    (state) => ({
      createEntity: state.createEntity,
      setModel: state.setModel,
      resetStatus: state.resetStatus,
    })
  );

  const handleUnmount = () => {
    resetStatus();
    console.log("handleUnmount Create page");
  };

  useEffect(() => {
    fetchFacesFromLocal();
    fetchFaces();
    return handleUnmount;
  }, []);

  useEffect(() => {
    if (uploadingFaceModel) {
      queryAnalysisResult();
    }
  }, [uploadingFaceModel]);

  const hideIngoDialog = () => {
    hideFaceModelInfo();
  };
  const cancelQuery = () => {
    cancelFaceModel();
  };

  return (
    <div className="bg-dark-bg lg:w-1/4 w-full mr-4  pt-[10px]  rounded-[15px] relative flex flex-col justify-between pl-[10px] pr-[10px]  ">
      <div className="flex flex-col w-full">
        <p className="text-white  relative text-left text-[20px] font-bold">
          Select Face
        </p>

        <div className="w-full relative flex-wrap flex flex-row justify-start lg:pt-[10px] pt-[0px]">
          <FaceModelInfoDialog
            show={showFaceModelInfoDialog}
            msg={""}
            onCancel={() => {
              hideIngoDialog();
            }}
          ></FaceModelInfoDialog>
          <LoadingDialog
            show={showUploadingDialog}
            title={uploadingDialogTitle}
            msg={""}
            onCancel={() => {
              cancelQuery();
            }}
          ></LoadingDialog>

          <div className="hidden lg:block ml-[4px] mr-[4px] w-full lg:ml-[16px]">
            <Grid
              container
              spacing={{ xs: 1, md: 1 }}
              columns={15}
              direction="row"
            >
              <Grid xs={5} sm={3} md={5} key={-1}>
                <FaceModelUploaderButton></FaceModelUploaderButton>
              </Grid>
              {faces &&
                faces.map((item, index) => {
                  return (
                    <Grid xs={5} sm={3} md={5} key={index}>
                      <FaceModelItem
                        faceModel={item}
                        index={index}
                      ></FaceModelItem>
                    </Grid>
                  );
                })}
            </Grid>
          </div>

          <div className="lg:hidden visible  flex overflow-x-auto h-[89px] scroll-snap-type-x scrollbar-track-transparent scrollbar-thumb-blue-500 items-center">
            <FaceModelUploaderButton></FaceModelUploaderButton>
            {faces &&
              faces.map((item, index) => {
                return (
                  <FaceModelItem faceModel={item} index={index}></FaceModelItem>
                );
              })}
          </div>
        </div>
      </div>
      <FaceEditCommitAction></FaceEditCommitAction>
    </div>
  );
}
