import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpFromBracket} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useUserStores} from "../../stores/UserStores";
import {useCreateStores} from "../../stores/CreateStores";
import {useNavigate} from "react-router-dom";

interface IProps {

}

export function FaceModelUploaderButton() {
    const navigation = useNavigate();

    const fileInputRef = React.createRef<HTMLInputElement>();
    const {userInfo} = useUserStores((state) => ({
        userInfo: state.userEntity,
    }));
    const {
        showHint,
        uploadFace,
    } = useCreateStores((state) => ({
        showHint: state.showHint,
        uploadFace: state.uploadFace,
    }));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            if (!["image/jpeg", "image/png"].includes(files[0].type)) {
                showHint("Only jpg/png format images are allowed for upload.");
                return; // 终止执行
            }
            if (files[0].size > 2 * 1024 * 1024) {
                showHint("The file size cannot exceed 2MB, please choose again.");
                return; // 终止执行
            }
            uploadFace(files[0]);
        }
    };

    const clickUpload = () => {
        if (
            userInfo &&
            userInfo.entitlement &&
            userInfo.entitlement.member_level &&
            userInfo.entitlement.member_level[0] > 0
        ) {
            fileInputRef.current?.click();
        } else {
            navigation("/subscribe");
        }
    };


    return (<div
        className="relative flex flex-row w-[69px] h-[69px] mr-[5px]  border-gary border-2 rounded-[35px] bg-gray justify-center items-center text-white scroll-snap-align-start lg:mt-[15px] mt-[5px]">
        <div className="h-[1px] text-transparent ">123123123</div>
        <input
            ref={fileInputRef}
            type="file"
            onChange={handleChange}
            className="w-[100%] h-[100%] bg-transparent outline-0  focus:outline-none focus:shadow-outline shadow-transparent invisible"
            accept="image/jpeg,image/png"
            placeholder=""
        ></input>
        <div
            className="absolute w-[100%] h-[100%] flex justify-center items-center"
            onClick={clickUpload}
        >
            <FontAwesomeIcon
                icon={faArrowUpFromBracket}
                size={"lg"}
                color={"white"}
            />
        </div>
        <div
            className="absolute top-[-2px] right-[-10px]  bg-red-500 h-[20px] rounded-[12.5px] text-white text-[12px] font-bold pl-2 pr-2">
            Pro
        </div>
    </div>)
}