import React, { useState } from "react";
import { FaceEditMiddle } from "./Face-Edit-Middle";
import { FaceEditRight } from "./Face-Edit-Right";
import { FaceEditLeftVideo } from "./Face-Edit-Left-Video";
import { useFaceEditStores } from "../../stores/FaceEditStores";
import { useCreateStores } from "../../stores/CreateStores";

//flex demo

export function FaceEdit() {
  return (
    <div
      className="h-screen overflow-y-auto bg-slate-950 no-scrollbar flex-row pb-[20px]"
    >
      <div className="flex lg:flex-row flex-col justify-content-center h-full  w-full pt-1 pl-4 pr-1 lg:pl-[2%] lg:pr-[2%] lg:mt-4">
        <FaceEditLeftVideo />
        <FaceEditMiddle />
        <FaceEditRight />
      </div>
    </div>
  );


}
