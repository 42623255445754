import {useUserStores} from "../../stores/UserStores";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect} from "react";
import toast from "react-hot-toast";
import {faGoogle} from "@fortawesome/free-brands-svg-icons"

export function AccountInfo() {
    const {userInfo, fetchUserInfo,resetRefresh,needRefresh} = useUserStores((state) => ({
        userInfo: state.userEntity,
        fetchUserInfo: state.fetchUserInfo,
        resetRefresh:state.resetRefresh,
        needRefresh:state.needRefresh
    }))

    useEffect(() => {
        if(needRefresh) {
            fetchUserInfo()
        }
    }, [userInfo])


    const handleUnmount = () => {
        resetRefresh()
        console.log('handleUnmount')
    }

    useEffect(() => {
        return handleUnmount
    }, []);

    const copy = () => {
        navigator.clipboard.writeText(userInfo?.openid ? userInfo.openid : '');
        toast.success('Copy Success')
    }

    const convertDate =(dateTimeStr:string) =>{
        const date = new Date(dateTimeStr)
        const yearMonthDay = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
        const time = dateTimeStr.replace('T', ' ').split(' ')[1]
        return yearMonthDay + ' '+time
    }
    return (
        <div
            className="flex flex-col pt-[5%] justify-start items-center h-screen w-screen bg-dark-bg pl-[5%] pr-[5%] lg:pl-[10%] lg:pr-[10%]">
            <div className='flex flex-col bg-gray w-full rounded-[20px] pb-[10px] pt-[10px]'>
                <div className='flex flex-row justify-between h-[50px]'>
                    <div className='text-[18px] text-white flex flex-row justify-start items-center pl-[2%]'>
                        <div className='font-bold'>Account Infomation</div>
                    </div>
                </div>
                <hr className="my-2 border-gray-d ml-[2%] mr-[2%]"/>
                <div className='flex flex-row justify-between h-[50px]'>
                    <div className='text-[18px] text-white flex flex-row justify-start items-center pl-[2%]'>
                        <div className=''>Account ID</div>
                    </div>
                    <div
                        onClick={() => copy()}
                        className='cursor-pointer flex flex-row justify-center items-center text-[16px] text-white pr-[2%]'>
                        <div>{userInfo && userInfo.openid ? userInfo.openid : ''}</div>
                        <FontAwesomeIcon className="ml-[5px] mr-[10px]" size={"sm"} color={"white"} icon={faCopy}/>
                    </div>
                </div>
                <hr className="my-2 border-gray-d ml-[2%] mr-[2%]"/>
                <div className='flex flex-row justify-between h-[50px]'>
                    <div className='text-[18px] text-white flex flex-row justify-start items-center pl-[2%]'>
                        <div className=''>Account</div>
                    </div>
                    <div
                        className='cursor-pointer flex flex-row justify-center items-center text-[18px] text-white pr-[2%]'>
                        <FontAwesomeIcon className="ml-[5px] pr-[10px]" size={"sm"} color={"white"} icon={faGoogle}/>
                    </div>
                </div>

                <hr className="my-2 border-gray-d ml-[2%] mr-[2%]"/>
                <div className='flex flex-row justify-between h-[50px]'>
                    <div className='text-[18px] text-white flex flex-row justify-start items-center pl-[2%]'>
                        <div className=''>Credit</div>
                    </div>
                    <div
                        className='cursor-pointer flex flex-row justify-center items-center text-[18px] text-white pr-[2%]'>
                        <div>{userInfo && userInfo.entitlement?.credit_point ? userInfo.entitlement.credit_point : '0'}</div>
                    </div>
                </div>

                {(userInfo && userInfo.entitlement && userInfo.entitlement.expires) && <div className='flex flex-row justify-between h-[50px]'>
                    <div className='text-[18px] text-white flex flex-row justify-start items-center pl-[2%]'>
                        <div className=''>Renewal Date</div>
                    </div>
                    <div
                        className='cursor-pointer flex flex-row justify-center items-center text-[18px] text-white pr-[2%]'>
                        <div>{userInfo && userInfo.entitlement?.expires ? convertDate(userInfo.entitlement.expires) : '0'}</div>
                    </div>
                </div>}
            </div>

        </div>)
}