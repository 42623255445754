import React, { useState } from "react";
import { Button, Spinner } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/common/Footer";
import { HomeHintDialog } from "./HomeHintDialog";

const userAgreeAgreement = "USER_AGREE_AGREEMENT";

export function Home() {
  const userHaveAgree = localStorage.getItem(userAgreeAgreement) || false;
  console.log("userHaveAgree = " + userHaveAgree);
  const [showAgreement, setShowAgreement] = useState(!userHaveAgree);
  const navigator = useNavigate();
  return (
    <div className="flex-col w-full h-screen bg-red-500">
      <div className="flex-col h-full bg-dark-bg">
        <div className="text-primary-color text-[32px] lg:text-[44px]  font-bold select-none">
          AI Magic Tool from Text
        </div>
        <div className="text-white text-[22px]lg:text-[18px]  select-none mt-[10px]">
          Deloris AI art tool makes your dream character come to reality.
        </div>
        <img
          src={require("../../../src/assets/home_banner.webp")}
          className="w-full select-none mt-[50px]"
        ></img>
        <div className="flex justify-center w-full absolute bottom-0 mb-[50px]">
          <Button
            placeholder={""}
            className="normal-case relative bg-gradient-to-r from-blue-500 to-pink-500 flex flex-row justify-center items-center text-[22px] bg-gray h-500 border-gray border-4 rounded-[30px] h-[60px] mt-[30px]"
          >
            <div
              className="flex gap-8"
              onClick={() => {
                navigator("/create");
              }}
            >
              Try it Now
            </div>
          </Button>
        </div>
      </div>
      <Footer></Footer>
      <HomeHintDialog
        show={showAgreement}
        msg={""}
        title={""}
        onCancel={function (): void {
          localStorage.setItem(userAgreeAgreement, "true");
          setShowAgreement(false);
        }}
      />
    </div>
  );
}
