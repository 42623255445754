import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Spinner} from "@material-tailwind/react";
import React from "react";

interface IProps {
    onConfirm: () => void,
    onCancel: () => void,
    open: boolean,
    deleting: boolean,

}

export function DeleteDialog(props: IProps) {

    const {onConfirm, onCancel, open, deleting} = props

    return (<Dialog
        placeholder={''}
        open={open} handler={(e) => {
        onCancel()
    }} className="bg-dark-bg">
        <DialogHeader
            placeholder={''}
            className="text-white">Delete Confirm</DialogHeader>
        <DialogBody
            placeholder={''}
            className="text-white">
            Are you sure to delete this creation?
        </DialogBody>
        <DialogFooter
            placeholder={''}
        >
            <Button
                placeholder={''}
                variant="text"
                color="green"
                onClick={(e) => {
                    e.stopPropagation()
                    onCancel()
                }
                }
                className="mr-1 text-white"
            >
                <span>Cancel</span>
            </Button>
            <Button
                placeholder={''}
                variant="gradient" color="red" onClick={(e) => {
                e.stopPropagation()
                onConfirm()
            }} className="flex flex-row justify-center items-center text-white">
                {deleting && <Spinner className="h-3 w-3 text-gray-900/50 mr-[10px]" color="purple"/>}
                <span>{deleting ? 'Deleting' : 'Confirm'}</span>
            </Button>
        </DialogFooter>
    </Dialog>)

}