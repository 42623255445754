import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Spinner} from "@material-tailwind/react";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faCircleQuestion, faCircleXmark} from "@fortawesome/free-solid-svg-icons";

interface IProps {
    show: boolean,
    msg:string,
    onCancel: () => void,
}

export function OnboardingDialog(props: IProps){

    const { show ,msg,onCancel} = props
    return (<Dialog
        onBlur={()=>{onCancel()}}
        placeholder={''}
        size={"xs"}
        open={show} handler={(e) => {
    }} className="bg-dark-bg max-w[180px] w-[180px]">
        <DialogBody
            placeholder={''}
            className="text-white">
            <div className="flex flex-col  items-center">
                <div className="text-white text-[20px] font-bold">Upgrade</div>
                <div className="text-white text-[14px]">We have improved the quality and resolution of the images.</div>


                <div className="flex flex-row justify-around items-stretch w-full">
                <div className='bg-red-alpha flex flex-col   pl-[10px] pr-[10px] rounded-[8px] pb-[15px] pt-[10px] mr-[2px] mt-[15px]'>
                    <div className={'flex flex-row justify-between items-center'}>
                        <div className={'font-bold text-red-600  text-[14px] lg:text-[18px]'}>Old Version</div>
                        <FontAwesomeIcon icon={faCircleXmark} size={"lg"} color='red' className='ml-[3px]'></FontAwesomeIcon>
                    </div>
                    <div className='flex flex-row justify-between pl-[5px] pr-[5px] mt-[5px] '>
                        <img
                            className={`object-cover object-center border-red-600 border-[2px] rounded-[10px]  w-[150px] h-[200px] lg:w-[300px] lg:h-[450px]  scroll-snap-align-start`}
                            src={require('../../../src/assets/onboarding_e_1.png')}
                        />
                    </div>
                </div>

                <div className='bg-green-alpha flex flex-col   pl-[10px] pr-[10px] rounded-[8px] pb-[15px] pt-[10px] mt-[15px]'>
                    <div className={'flex flex-row justify-between items-center'}>
                        <div className={'font-bold text-green-400  text-[14px] lg:text-[18px]'}>New Version</div>
                        <FontAwesomeIcon icon={faCircleCheck} size={"lg"} color='green' className='ml-[3px]'></FontAwesomeIcon>
                    </div>
                    <div className='flex flex-row justify-between pl-[5px] pr-[5px] mt-[5px]'>
                        <img
                            className={`object-cover object-center border-green-400 border-[2px] rounded-[10px]  w-[150px] h-[200px] lg:w-[300px] lg:h-[450px] scroll-snap-align-start`}
                            src={require('../../../src/assets/onboarding_e_2.jpeg')}
                        ></img>
                    </div>
                </div>
                </div>


                <Button
                    placeholder={''}
                    onClick={()=>{onCancel()}}
                    className="relative bg-gradient-to-r from-blue-500 to-pink-500 flex flex-row justify-center items-center text-[22px] bg-gray w-[50%]  h-500 border-gray border-4 rounded-[30px] h-[50px] mt-[30px] ">
                    <div>Try it</div>
                </Button>
            </div>
        </DialogBody>
    </Dialog>)
}
