import {useGoogleOneTapLogin} from "@react-oauth/google";
import React from "react";

export interface IProps{
    onClick:()=>void
}
export  function GoogleLoginBtn(iProps:IProps) {
    const {onClick} = iProps

    return (   <div
        className='font-medium cursor-pointer w-[100%] bg-white h-[60px] text-black rounded-[30px] text-[22px] flex flex-rows items-center justify-center'
        onClick={() => {
            onClick()
        }}>
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none">
                <path
                    d="M6.166 12.08c0-.655.11-1.283.305-1.872L3.045 7.614A10 10 0 0 0 2 12.08a9.99 9.99 0 0 0 1.043 4.462l3.425-2.598a5.93 5.93 0 0 1-.302-1.864"
                    fill="#FBBC05"></path>
                <path
                    d="M12.186 6.124a5.926 5.926 0 0 1 3.75 1.327l2.963-2.931C17.093 2.963 14.779 2 12.186 2 8.161 2 4.701 4.281 3.045 7.614l3.429 2.594a5.996 5.996 0 0 1 5.713-4.085"
                    fill="#EA4335"></path>
                <path
                    d="M12.23 17.902c-2.687 0-4.95-1.697-5.744-4.059l-3.444 2.578C4.707 19.734 8.183 22 12.23 22c2.496 0 4.879-.868 6.668-2.496l-3.27-2.477c-.922.569-2.084.876-3.4.876"
                    fill="#34A853"></path>
                <path
                    d="M22 11.984c0-.592-.094-1.23-.233-1.821H12.23v3.87h5.49a4.505 4.505 0 0 1-2.09 2.994l3.27 2.477c1.877-1.709 3.1-4.255 3.1-7.52"
                    fill="#4285F4"></path>
                <path
                    d="M6.166 12.08c0-.655.11-1.283.305-1.872L3.045 7.614A10 10 0 0 0 2 12.08a9.99 9.99 0 0 0 1.043 4.462l3.425-2.598a5.93 5.93 0 0 1-.302-1.864"
                    fill="#FBBC05"></path>
                <path
                    d="M12.186 6.124a5.926 5.926 0 0 1 3.75 1.327l2.963-2.931C17.093 2.963 14.779 2 12.186 2 8.161 2 4.701 4.281 3.045 7.614l3.429 2.594a5.996 5.996 0 0 1 5.713-4.085"
                    fill="#EA4335"></path>
                <path
                    d="M12.23 17.902c-2.687 0-4.95-1.697-5.744-4.059l-3.444 2.578C4.707 19.734 8.183 22 12.23 22c2.496 0 4.879-.868 6.668-2.496l-3.27-2.477c-.922.569-2.084.876-3.4.876"
                    fill="#34A853"></path>
                <path
                    d="M22 11.984c0-.592-.094-1.23-.233-1.821H12.23v3.87h5.49a4.505 4.505 0 0 1-2.09 2.994l3.27 2.477c1.877-1.709 3.1-4.255 3.1-7.52"
                    fill="#4285F4"></path>
            </g>
        </svg>
        <div className='ml-[10%]'>
            Continue with Google
        </div>
    </div>);

}