import React, { useEffect, useState } from "react";
import { useFaceEditStores } from "../../stores/FaceEditStores";
import { LoadingDialog } from "../../components/create/LoadingDoialog";
import { FaceEditButtonActionPanel } from "../../components/face-edit/FaceEditButtonActionPanel";
import { DeleteDialog } from "../../components/task/DeleteDoialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faClapperboard,
  faFilm,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "@material-tailwind/react";
import { TaskProcessBar } from "../../components/task/TaaskProcessBar";
import { FaceEditDemoShowDialog } from "./FaceEditDemoShowDialog";
import { ProjectStatusEnum } from "../../api/task/TaskApi";

export function FaceEditMiddle() {
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [showDemoDialog, setShowDemoDialog] = useState(false);
  useEffect(() => {
    setInputRef(fileInputRef.current); // 使用 current 属性
  }, []);

  const {
    selectModeType,
    subGenVideoTask,
    uploadVideo,
    fetchTasks,
    uploadingDialogTitle,
    showUploadingDialog,
    cancelUploadVideo,
    project,
    previewOriginVideo,
    setShowDeleteDialog,
    deleteTask,
    showDeleteDialog,
    deleting,
    videoCreateProcessing,
    queryFaceEditTask,
    createEntity,
    awsUrlEntity,
    getAwsUrl,
  } = useFaceEditStores((state) => ({
    selectModeType: state.selectModeType,
    fetchTasks: state.fetchTasks,
    uploadVideo: state.uploadVideo,
    subGenVideoTask: state.subGenVideoTask,
    uploadFace: state.uploadFace,
    uploadingDialogTitle: state.uploadingDialogTitle,
    showUploadingDialog: state.showUploadingDialog,
    cancelUploadVideo: state.cancelUploadVideo,
    project: state.project,
    previewOriginVideo: state.previewOriginVideo,
    setShowDeleteDialog: state.setShowDeleteDialog,
    deleteTask: state.deleteTask,
    showDeleteDialog: state.showDeleteDialog,
    deleting: state.deleting,
    videoCreateProcessing: state.videoCreateProcessing,
    queryFaceEditTask: state.queryFaceEditTask,
    createEntity: state.createEntity,
    awsUrlEntity: state.awsUrlEntity,
    getAwsUrl: state.getAwsUrl,
  }));

  //把图片展示到前台
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      if (!["video/mp4"].includes(files[0].type)) {
        console.log("Only mp4 format  are allowed for upload.");
        return; // 终止执行
      }
      if (files[0].size > 500 * 1024 * 1024) {
        console.log("The file size cannot exceed 500MB, please choose again.");
        return; // 终止执行
      }
      uploadVideo(files[0]);
      console.log("上传视频");
      const localUrl = URL.createObjectURL(files[0]);
    }
  };

  const clickUpload = () => {
    if (awsUrlEntity == null) {
      console.log("#clickUpload #awsUrlEntity is null");
      return;
    }

    fileInputRef.current?.click();
  };
  const cancelQuery = () => {
    cancelUploadVideo();
  };
  const showInit = () => {
    if (!project) {
      return true;
    } else {
      return false;
    }
  };

  const previewOriVideo = () => {
    if (
      project &&
      project.project_segments &&
      project.project_segments.length > 0 &&
      project.project_segments[0].origin_video_url
    ) {
      return project.project_segments[0].origin_video_url;
    }
    return "";
  };

  const previewSwapVideo = () => {
    if (
      project &&
      project.project_segments &&
      project.project_segments.length > 0 &&
      project.project_segments[0].gen_video_url
    ) {
      return project.project_segments[0].gen_video_url;
    }
    return "";
  };
  const showPreview = () => {
    if (!project) {
      return false;
    } else {
      if (
        project &&
        project.project_segments &&
        project.project_segments.length > 0 &&
        project.project_segments[0].origin_video_url
      ) {
        return true;
      }
      return false;
    }
  };

  const hideDialog = () => {
    setShowDeleteDialog(false);
  };

  const handleConfirm = () => {
    deleteTask();
  };

  useEffect(() => {
    getAwsUrl();
  }, []);

  let time = (project && project.project_segments[0].estimated_time) || 30;
  time = time * 1000;
  console.log("预估的time = " + time);

  const videoFailStatus = () => {
    const status_code = (project && project.project_status[0]) || 0;
    if (
      status_code == ProjectStatusEnum.SwapGenerateFailed ||
      status_code == ProjectStatusEnum.FileNotSupport ||
      status_code == ProjectStatusEnum.FileOverSize ||
      status_code == ProjectStatusEnum.VideoNotContainFaces
    ) {
      return true;
    } else {
      return false;
    }
  };
  const errorText = () => {
    const status_code = (project && project.project_status[0]) || 0;
    if (status_code == ProjectStatusEnum.SwapGenerateFailed) {
      return "Analysis Failed";
    } else if (status_code == ProjectStatusEnum.FileNotSupport) {
      return "Upload file type not support，please retry";
    } else if (status_code == ProjectStatusEnum.FileOverSize) {
      return "Upload file is oversize，please retry";
    } else if (status_code == ProjectStatusEnum.VideoNotContainFaces) {
      return "The uploaded video does not contain any faces. Please try using a clearer video";
    }
    return "Analysis Failed";
  };

  return (
    <div className="bg-dark-bg lg:w-1/2 w-full lg:h-full h-full lg:mx-2 lg:mt-0 lg:mb-0  mb-2   rounded-[15px] p-1 overflow-hidden relative">
      <LoadingDialog
        show={showUploadingDialog}
        title={uploadingDialogTitle}
        msg={""}
        onCancel={() => {
          cancelQuery();
        }}
      ></LoadingDialog>
      <DeleteDialog
        open={showDeleteDialog}
        onConfirm={() => {
          handleConfirm();
        }}
        onCancel={() => {
          hideDialog();
        }}
        deleting={deleting}
      ></DeleteDialog>
      <div
        className={`rounded-[10px] border-dashed  ${
          showInit() ? "border-2" : "border-0"
        } border-white flex flex-col justify-center w-full h-full`}
      >
        {/*<img  className={`${imagePath.length !== 0 ? "visible" : "invisible"} absolute grow`} src={imagePath}/>*/}

        {!videoCreateProcessing  && !videoFailStatus() && showInit() && (
          <div className={`flex flex-col justify-center items-center`}>
            <input
              id="upload_input"
              ref={fileInputRef}
              className="opacity-0 top-0 left-0 right-0 bottom-0"
              type="file"
              accept="MPEG-4"
              placeholder=""
              onChange={(e) => handleChange(e)}
            />
            <div className="text-white font-medium text-[22px] mt-[2px] lg:mt-[5px]">
              Swap face with video
            </div>
            <div
              className="text-white font-medium text-[18px] mt-[2px] lg:mt-[5px]"
              onClick={() => {
                setShowDemoDialog(true);
              }}
            >
              <div className={"flex flex-row"}>
                <FontAwesomeIcon
                    icon={faFilm}
                    size={"lg"}
                    color={'white'}
                    className="pt-[2px]"
                ></FontAwesomeIcon>
                <div className={"ml-[5px]"}>Preview Effect</div>
              </div>
            </div>

            <div className="text-white-hint font-medium text-[18px] mt-[1px] lg:mt-[5px]">
              Maximum 500MB
            </div>
            <FontAwesomeIcon
              size={"5x"}
              color={"white"}
              className="border-white p-[10px] mb-[5px] lg:mb-[20px] rounded-[10px]"
              icon={faClapperboard}
            ></FontAwesomeIcon>
            <div
              onClick={clickUpload}
              className={`w-120 h-50 rounded-full pl-6 pr-6 pt-3 pb-3 ${
                awsUrlEntity == null
                  ? " bg-zinc-400"
                  : "bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"
              }   font-medium`}
            >
              <div className="text-white font-sans font-bold">Upload Video</div>
            </div>
            <div className="text-white-hint font-medium text-[18px] mt-[5px]">
              step1: upload video
            </div>
            <div className="text-white-hint font-medium text-[18px] mt-[5px]">
              step2: add face and swap
            </div>
          </div>
        )}

        {!videoCreateProcessing  && !videoFailStatus() && showPreview() && (
          <div
            className={`h-full w-full flex flex-col justify-between overflow-hidden relative  `}
          >
            {previewOriginVideo && (
              <video
                className={`lg:w-full lg:h-[90%] h-full rounded-lg mb-[60px] pb-[60px]`}
                controls
              >
                <source src={previewOriVideo()} type="video/mp4" />
              </video>
            )}
            {!previewOriginVideo && (
              <video
                className={`lg:w-full lg:h-[90%] h-full rounded-lg mb-[60px] pb-[60px]`}
                controls
              >
                <source src={previewSwapVideo()} type="video/mp4" />
              </video>
            )}
            <div className={"w-full absolute bottom-0"}>
              {!videoFailStatus() && (
                <FaceEditButtonActionPanel></FaceEditButtonActionPanel>
              )}
            </div>
          </div>
        )}

        {videoCreateProcessing && !videoFailStatus() && (
          <div className="h-full w-full left flex gap-8 flex-col items-center justify-center">
            <div className=" flex flex-col">
              <TaskProcessBar duration={time} />
              <div className="text-white text-[22px] mt-[10px]">
                 face swapping in progress
              </div>
            </div>
          </div>
        )}

        {videoFailStatus() && (
          <div className="flex-row justify-start items-center w-full h-full">
            <p className="text-red-800 text-[24px] mt-[24px]">Analysis Failed</p>
            <p className="text-red-800 text-[18px] mt-[18px]">{errorText()}</p>
          </div>
        )}

        {
          <FaceEditDemoShowDialog
            show={showDemoDialog}
            msg={""}
            title={""}
            onCancel={function (): void {
              setShowDemoDialog(false);
            }}
          />
        }
      </div>
    </div>
  );
}
